import { render, staticRenderFns } from "./FormTextAreaAutoHeight.vue?vue&type=template&id=b5c5c204&"
import script from "./FormTextAreaAutoHeight.vue?vue&type=script&lang=js&"
export * from "./FormTextAreaAutoHeight.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports